import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'
import { getImage } from 'gatsby-plugin-image'

import '../scss/pages/bank.scss'
import Posts from '../components/Posts/Posts'
import PrimaryButton from '../components/PrimaryButton/PrimaryButton'
import SecondaryButton from '../components/SecondaryButton/SecondaryButton'
import config from '../../config.json'

const BanksPage = () => {
    const data = useStaticQuery(query);
    const seo = data.wpPage.seo;
    const banks = data.allWpPost.edges;

    return (
        <Layout>
            <Seo title={seo.title} description={seo.metaDesc} keywords={seo.focuskw} pathname="/banki/" />
            <div className="page-top-section">
                <div className="container custom-container">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="small-header text-center mb-0 text-white">Banki</p>
                            <h1 className="header-h1 text-center mt-15 mb-30 text-white">Które banki oferują ubezpieczenie na życie przy kredycie?</h1>
                            <p className="paragraph text-center text-white font-light mb-0">Znajdź ofertę ubezpieczenia na życie do kredytu hipotecznego w swoim banku.  Sprawdź, które banki oferują ubezpieczenie na życie do kredytu i jaka jest ich oferta – wybieraj świadomie!</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bank section-padding">
                <div className="container">
                    <div className="row">
                        {banks.map((item) => {
                            let bank = item.node;

                            let logo = false;
                            if(Boolean(bank.bank.logoBanku)){
                                logo = getImage(bank.bank.logoBanku.localFile)
                            }

                            return  <div className="col-md-12">
                                        <div className="bank-information mt-15 mb-15">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    {Boolean(logo) ? (
                                                        <div className="logo" style={{ backgroundImage: `url(${logo.images.fallback.src})` }}></div>
                                                    ) : (
                                                        <div className="logo"></div>
                                                    )}
                                                </div>
                                                <div className="col-md-9 bank-information-container">
                                                    <h2 className="header-h2 black-text-color title mb-15">{bank.bank.tytulOpisu}</h2>
                                                    <p className="description mb-0 black-text-color font-light">{bank.bank.opis}</p>
                                                </div>
                                            </div>
                                            <div className="row icons-row">
                                                <div className="col-md-4">
                                                    <div className="d-flex">
                                                        <div className="icon orange-icon">
                                                            <i className="piggy-bank-icon"></i>
                                                        </div>
                                                        <div className="content saving-content">
                                                            <p className="small-title mb-0">Największa oszczędność <br />na polisie</p>
                                                            <p className="p-value">{bank.bank.najwiekszaOszczednoscNaPolisie}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="d-flex">
                                                        <div className="icon">
                                                            <i className="money-bag-icon"></i>
                                                        </div>
                                                        <div className="content">
                                                            <p className="small-title mb-0">Średnia suma <br />ubezpieczenia</p>
                                                            <p>{bank.bank.sredniaSumaUbezpieczenia}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="d-flex mb-0">
                                                        <div className="icon">
                                                            <i className="request-icon"></i>
                                                        </div>
                                                        <div className="content">
                                                            <p className="small-title mb-0">Ilość zapytań <br />o polisę</p>
                                                            <p>{bank.bank.iloscZapytanOPolise}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-end btn-group-container">
                                                <div className="btn-group mt-30">
                                                    <SecondaryButton link={`/banki/${bank.slug}/`} title="Sprawdź szczegóły" />
                                                    <PrimaryButton link={config.CALC_LINK} title="Porównaj ofertę" icon="unbalanced-icon" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        })}
                    </div>
                </div>
            </div>
            <Posts />
        </Layout>
    )
}

export default BanksPage

const query = graphql`
{
    wpPage(slug: {eq: "banki"}) {
      seo {
        metaDesc
        title
        focuskw
      }
    }
    allWpPost(
        filter: {categories: {nodes: {elemMatch: {name: {eq: "Bank"}}}}}
      ) {
        edges {
            node {
                slug
                bank {
                    iloscZapytanOPolise
                    najwiekszaOszczednoscNaPolisie
                    numerTelefonu
                    tytulOpisu
                    opis
                    sredniaSumaUbezpieczenia
                    logoBanku {
                        altText
                        mediaItemUrl
                        localFile {
                            childImageSharp {
                            gatsbyImageData(width: 600)
                            }
                        }
                    }
                }
            }
        }
    }
}
`
